import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  AboutHero,
  AboutDescription,
  AboutTeam,
  AboutOptions,
  AboutOptionsWithTitle,
  AboutContact,
  AboutOurValues,
} from 'components/sections';

import aboutPageMotion from 'motion/about.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutContact, functionSliderAbout, functionAboutAccordion, functionAboutValues } from 'motion/about.js';
import { indexScrollState} from 'motion/index.js';

indexScrollState();


const AboutPage = ({
  data: {
    pages: { about },
  },
}) => (
  <Layout onVisible={aboutPageMotion}>
    <SEO title={about.seo.title} description={about.seo.meta} keywords={about.seo.keywords} />

    <AboutHero content={about} />

    <AboutDescription content={about} />

    <ViewportEnterAnimation offset={100} animation={functionAboutValues}>
      <AboutOurValues {...about.ourValues} />
    </ViewportEnterAnimation>

    <ViewportEnterAnimation offset={100} animation={functionSliderAbout}>
      <AboutTeam content={about} />
    </ViewportEnterAnimation>


    <ViewportEnterAnimation offset={100} animation={functionAboutContact}>
      <AboutContact content={about.contactFooter} />
    </ViewportEnterAnimation>
  </Layout>
);

export const query = graphql`
  query AboutPage {
    pages {
      about {
        hero {
          title
          subtitle
          img
          shot
        }
        faq {
          title
          questions {
            question
            answer
          }
        }
        description {
          img
          usp
          description1
          description2
          ethicsTitle
          ethicsBody
          ethicsBtn
        }
        team {
          title
          description
        }
        ourValues {
          title
          values {
            title
            content
          }
        }
        contactFooter{
          join{
            img
            title
            description
          }
          refer{
            img
            title
            description
          }
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;

export default AboutPage;
